<template>
  <v-container class="fill-height">
    <v-row align-content="center" justify="center" class="fill-height">
      <v-col cols="12" sm="11" md="10" lg="8" xl="5">
        <v-card elevation="14" outlined shaped>
          <v-card-title
            >DSE-Scanner
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = true">
              <v-icon>mdi-file-search-outline</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="9">
                  <v-file-input
                    label="Excel-Datei (.xlsx)"
                    outlined
                    dense
                    color="#37b6ae"
                    :disabled="scan_loading"
                    accept=".xlsx"
                    :rules="[file_rule]"
                    v-model="file"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="3" class="text-right">
                  <v-btn
                    block
                    color="#37b6ae"
                    @click="scan()"
                    class="white--text"
                    :disabled="scan_loading || file == null"
                    >Scannen</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-expand-transition>
              <v-row v-show="scan_loading" justify="center">
                <v-col cols="10" class="pb-10 pt-5">
                  <v-progress-linear
                    color="#37b6ae"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-expand-transition>

            <v-expand-transition>
              <v-row v-show="error" justify="center">
                <v-col cols="10" class="pb-10 pt-5">
                  <v-alert
                    v-model="error"
                    border="left"
                    color="red"
                    type="error"
                    dismissible
                  >
                    Die Datei konnte nicht verarbeitet werden
                  </v-alert>
                </v-col>
              </v-row>
            </v-expand-transition>

            <v-expand-transition>
              <div v-show="scan_result">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="10" class="pb-6 pt-5 text-center">
                    <form method="get" :action="link">
                      <v-btn
                        elevation="4"
                        large
                        rounded
                        color="#37b6ae"
                        class="white--text"
                        type="submit"
                      >
                        <v-icon class="mr-3">mdi-microsoft-excel</v-icon>Datei
                        herunterladen</v-btn
                      >
                    </form>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title
          >Suchbegriffe
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="search"
                  color="#37b6ae"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _axios from "axios";

const axios = _axios.create({
  baseURL: "https://scanner.keyed.de/",
});

export default {
  name: "UploadCard",

  data: () => ({
    scan_loading: false,
    scan_result: false,
    error: false,
    file: null,
    link: "#",
    dialog: false,
    search: "",
  }),
  mounted() {
    this.search =
      "Google Inc\nPrivacy Shield\nShield\nPrivacy-Shield\n2016\n2017\n2018\neRecht24\n";
  },
  methods: {
    scan() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = false;
      this.scan_result = false;
      this.scan_loading = true;

      /**
       * Datei zum PHP-Skript senden
       * Wenn antwort da ist dann
       *
       * this.file = null;
       * this.scan_loading = false;
       * this.scan_result = true;
       *
       * Und this.link setzen
       *
       */

      let formData = new FormData();
      formData.append("excel_file", this.file, this.file.name);

      let search_array = [];
      this.search.split(/\n/).forEach((elem) => {
        if (elem != "") search_array.push(elem);
      });

      formData.append("search", JSON.stringify(search_array));

      axios
        .post("scanner.php", formData)
        .then((response) => {
          this.link = response.data;
          this.file = null;
          this.scan_loading = false;
          this.scan_result = true;
        })
        .catch((error) => {
          this.file = null;
          this.scan_loading = false;

          // Fehler anzeigen
          this.error = true;

          // Fehler in der Console loggen
          console.log(error);
        });
    },
    file_rule(file) {
      if (file == null) return true;

      if (file.name.endsWith(".xlsx")) return true;

      return "Bitte wählen Sie eine Excel-Datei aus";
    },
  },
};
</script>
