<template>
  <v-app>
    <v-main>
      <UploadCard />
    </v-main>
    <!--<v-footer padless>
      <v-card class="flex" flat>
        <v-card-text class="py-2 text-center">
          {{ new Date().getFullYear() }} — Keyed GmbH
        </v-card-text>
      </v-card>
    </v-footer>-->
  </v-app>
</template>

<script>
import UploadCard from "./components/UploadCard";

export default {
  name: "App",

  components: {
    UploadCard,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.v-application {
  background-color: #f3f3f3 !important;
}
</style>